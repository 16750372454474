import { ClipLoader } from 'react-spinners';
import './App.css';
import EventBox from './components/eventBox';
import {useEffect, useState} from "react";
import { Events } from './types/events';
import api from "./service/api"

function App() {

  const[eventsList, setEventsList] = useState<Events[]>();

  useEffect(() =>{
    api
      .get<Events[]>("api/Calendar")
      .then((response) => setEventsList(response.data));
  }, []);


  return (
    <div className="App">
      <img src="https://agenda.icfcapao.com.br/imgs/LogoAgendaCpNews.png"/>
      <div className='EventsDiv'>
          {
            !eventsList ? <ClipLoader color={"#43715D"} size={150} speedMultiplier={1}/> :
            eventsList?.map((eventUnit, i) => {
              return <EventBox key={i} {...eventUnit}/>
            })
          }
        </div>
    </div>
  );
}

export default App;
