import { Events } from "../types/events";
import { EventDateDayText, EventDateDiv, EventDateMonthText, EventDescriptionText, EventInfoDiv, EventMainDiv, EventNameText, MinistryDescriptionText } from "./style";
import { useRef } from "react";

export default function EventBox({start, end, summary, desc}: Events){

    const linkSala = useRef(desc.link)
    const startDate = new Date(start);
    const endDate = new Date(end);
    const monthName = startDate.toLocaleString('pt-BR', { month: 'long' }).substring(0,3).toUpperCase();

    return (
        <EventMainDiv>
            <EventDateDiv>
                {!desc.recorrente && <EventDateDayText>{startDate.getDate()}</EventDateDayText>}
                <EventDateMonthText>{monthName}</EventDateMonthText>
            </EventDateDiv>
            <EventInfoDiv>
                {summary && <EventNameText>{summary}</EventNameText>}
                {startDate && <EventDescriptionText><b>Horário:</b> Das {startDate.getHours()} ate às {endDate.getHours()}</EventDescriptionText>}
                {desc.descricao && <EventDescriptionText><b>Descrição:</b> {desc.descricao }</EventDescriptionText>}
                {desc.responsavel && <EventDescriptionText><b>Responsável:</b> {desc.responsavel}</EventDescriptionText>}
                {desc.local && <EventDescriptionText><b>Local:</b> {desc.local}</EventDescriptionText>}
                {desc.link && <EventDescriptionText><b>Link:</b>  <a href= {linkSala.current}>Clique aqui</a></EventDescriptionText>}
                {desc.ministerio && <MinistryDescriptionText>{desc.ministerio}</MinistryDescriptionText>}
            </EventInfoDiv>
        </EventMainDiv>
    )
}